var Order = (function (o, $) {

    var order = o || {},
        siteUrl = '/',
        siteToken = '',
        selected = {h: -1, m: -1, s: -1},
        itemCnt = 0,
        itemPrice = 1,
        total = 0,
        currency = '&euro;',
        activeColumn = 'hour',
        wrapperScrollApi = null,
        lockSearcherTo = null,
        itemStatePending = false,
        col1Done = false,
        col2Done = false,
        cropData = {},
        /**
         * Zárolt másodperceket ennyi másodpercenként keresi
         */
        lockSearchInterval = 5,
        /**
         * Ezek az órák beteltek
         *
         * @type {Array}
         */
        inactiveHours = [],
        isEU = false,
        /**
         * Óra, perc másodperc oszlopk scrollgombjai
         * indukálják a jScrollPane scroll funkciót
         */
        chooserScroll = function () {
            $('.chooser').find('.inner').jScrollPane();
        },

        /**
         * Aktív oszlop kijelölése, óra, perc vagy másodperc
         *
         * @param {string} selector az aktív oszlop jQuery selectora
         * @param {string} [pattern=''] számjegyek cseréjéhez használt minta:
         *          AA = előző oszlopban kijelölt fix időrész helyőrzője
         *          BB = kiemelt inkrementált számsor helyőrzője
         *          xx és : = egy az egyben megjelenítésre kerül
         * @param {boolean} [noAnim=false] border animálás kikapcsolása
         */
        setActiveCol = function (selector, pattern, noAnim) {
            var na = typeof noAnim == 'undefined' ? false : noAnim;
            $(selector)
                .find('.list').addClass('active' + (na ? ' noanimation' : ''))
                .next().removeClass('hidden');

            if (selector !== '.hour.chooser') {
                $(selector).find('.item').removeClass('locked');
            }

            if (typeof pattern == 'string') {
                $(selector).find('.item').each(function (i) {
                    var str = pattern.replace('AA', fillZero(selected.h));
                    str = str.replace('BB', '<span>' + fillZero(i) + '</span>');
                    $(this)
                        .attr('data-value', i)
                        .html(zeroo(str));
                });
            }
        },

        /**
         * Egy helyiértékes számot kitölrti nullával (O)
         *
         * @param s
         * @return {*}
         */
        fillZero = function (s) {
            return s.toString().length == 1 ? ('0' + s) : s;
        },

        /**
         * nullát o-ra cseréli
         *
         * @param s
         * @return {string}
         */
        zeroo = function (s) {
            return s.toString().replace(/0/g, 'O');
        },

        getSelectedTime = function (sep) {
            var s = sep || ':';
            //return fillZero(zeroo(selected.h)) + s + fillZero(zeroo(selected.m)) + s + fillZero(zeroo(selected.s))
            return fillZero(selected.h) + s + fillZero(selected.m) + s + fillZero(selected.s)
        },

        /**
         * Zárolt órák, percek, másodpercek inaktiválása
         * Időszakosan lefutó függvény
         */
        setLock = function (start) {
            // előző (ha volt) ütemezett lekérés törlése
            clearInterval(lockSearcherTo);

            if (start) {
                lockRequest();
            }

            lockSearcherTo = setInterval(function () {
                lockRequest();
            }, lockSearchInterval * 1000);
        },

        /**
         * Zárolt órák, percek, másodpercek XHR lekérés
         */
        lockRequest = function () {
            if (itemStatePending) {
                return false;
            }

            $.ajax({
                url: siteUrl + '/getLock',
                type: 'POST',
                headers: siteToken,
                timeout: 4000,
                data: {h: selected.h, m: selected.m}
            }).done(function (result) {
                /**
                 * @var {{ locked: { h: Array, m: Array, s: Array }, currentPrice: int, price: string, nextPrices: string }} result
                 */
                $('.item').removeClass('locked');
                $('#order-spinner').hide();
                lockSet(result.locked);
                order.setItemPrice(result.currentPrice).setContingent(result);
            });
        },

        /**
         * Zárolt másodpercek inaktiválása
         *
         * @param {{ h: Array, m: Array, s: Array }} locked tömb a zárolt másodpercekkel
         */
        lockSet = function (locked) {
            var hourCol = $('.hour.chooser'),
                minuteCol = $('.minute.chooser'),
                secondCol = $('.second.chooser');

            if (locked.h.length > 0) {
                hourCol.find('.item').removeClass('locked');

                $.each(locked.h, function (i, lock) {
                    hourCol.find('.item[data-value=' + lock + ']').addClass('locked');
                });
            }

            if (locked.m.length > 0) {
                minuteCol.find('.item').removeClass('locked');

                $.each(locked.m, function (i, lock) {
                    minuteCol.find('.item[data-value=' + lock + ']').addClass('locked');
                });
            }

            if (locked.s.length > 0) {
                secondCol.find('.item').removeClass('locked');

                $.each(locked.s, function (i, lock) {
                    secondCol.find('.item[data-value=' + lock + ']').addClass('locked');
                });
            }
        },

        /**
         * Másodperc kiválasztás vezérlése
         * /pic-a-second menüpont
         */
        chooserControl = function (preselect) {
            var col1Done = preselect,
                col2Done = preselect,
                chooser = $('.chooser');

            chooser.on('click', '.item:not(.locked)', function () {
                if ($(this).hasClass('locked')) {
                    return;
                }

                var idx = chooser.index($(this).parents('.chooser')),
                    tInfo = $('.tinfo'),
                    leftSide = $('.left-side'),
                    list = $(this).parents('.list'),
                    minuteInfo = $('.minute-info'),
                    minuteCol = $('.minute'),
                    secondInfo = $('.second-info');

                switch (idx) {
                    // óra kiválasztása
                    case 0:
                        // kiválasztott óra mentése
                        selected.h = $(this).data().value;
                        $('.hour-info').find('span').html(fillZero(selected.h));

                        // ha volt előző kijelölés azt le kell szedni
                        $('.hour').find('.item').removeClass('active');
                        minuteCol.find('.item').removeClass('active');

                        // következő oszlop aktívvá tétele
                        setActiveCol('.minute.chooser', 'AA:BB:xx');
                        tInfo.removeClass('active');
                        minuteInfo.addClass('active');

                        Picamobile.chooseTime('minute');

                        // Míg nem tölt be addig lockolt minden másodperc
                        $('#order-spinner').show();
                        $('.item').addClass('locked');

                        setLock(true);

                        col1Done = true;
                        col2Done = false;

                        $('.tinfo.minute-info').find('span').html('XX');
                        $('.tinfo.second-info').find('span').html('XX');

                        leftSide.find('.chooser').css('bottom', '-1000px');
                        leftSide.find('.minute.chooser').css('bottom', '-10px');

                        activeColumn = 'minute';

                        break;
                    // perc kiválasztása
                    case 1:
                        if (!col1Done) {
                            return;
                        }

                        // kiválasztott perc mentése
                        selected.m = $(this).data().value;
                        minuteInfo.find('span').html(fillZero(selected.m));
                        // ha volt előző kijelölés azt le kell szedni
                        minuteCol.find('.item').removeClass('active');

                        // következő oszlop aktívvá tétele
                        setActiveCol('.second.chooser', fillZero(selected.h) + ':' + fillZero(selected.m) + ':BB');
                        tInfo.removeClass('active');
                        secondInfo.addClass('active');
                        leftSide.find('.chooser').css('bottom', '-1000px');
                        leftSide.find('.second.chooser').css('bottom', '-10px');

                        // Míg nem tölt be addig lockolt minden másodperc
                        $('#order-spinner').show();
                        $('.item').addClass('locked');

                        setLock(true);

                        col2Done = true;
                        activeColumn = 'second';


                        break;
                    case 2:
                        if (!col1Done || !col2Done || itemStatePending) {
                            return;
                        }

                        var elem = $(this);

                        // kiválasztott másodperc mentése
                        selected.s = elem.data().value;

                        // mehet a kosárba
                        order.addToBasket(function() {
                            // ScrollPanel elrejtése
                            tInfo.find('span').html('XX');
                            tInfo.removeClass('active');
                            leftSide.find('.chooser').css('bottom', '-1000px');

                            elem.addClass('locked');

                            col1Done = false;
                            col2Done = false;
                        });

                        break;
                }

                $(this).addClass('active');

            });
        };

    order.hideChooser = function () {
        $('.item').removeClass('active');
        $('.left-side').find('.chooser').css('bottom', '-1000px');
    };

    order.setContingent = function (content) {
        var box = $('.second-chooser').find('.after');
        box.html(content.nextPrices);

        $('.current-price').html(content.price);
    };

    order.addToBasket = function (callback) {
        if (itemStatePending) {
            return false;
        }

        itemStatePending = true;

        col1Done = false;

        // másodperc lock
        $.ajax({
            type: 'POST',
            url: siteUrl + '/lockSecond',
            headers: siteToken,
            data: selected
        }).success(function (result) {
            if (result.success) {
                order.setItemPrice(result.currentPrice).setContingent(result);

                itemCnt = result.itemCount;
                var wrapper = $('.items-wrapper');

                var itemTpl =
                    '<li data-time="' + getSelectedTime() + '">' +
                    '<div class="time">TIME</div>' +
                    '<div class="quantity">PRICE <span class="currency-symbol">' + currency + '</span></div>' +
                    '<div class="control"><a href="#" onclick="Order.removeFromBasket(\'' + getSelectedTime() + '\', event);"><i class="fa fa-times-circle"></i><i class="fa fa-cog fa-spin hidden"></i></a></div>' +
                    '</li>';

                // kosár elem összesen
                $('.item-summary').html('<i class="fa fa-shopping-cart"></i><span>' + itemCnt + '</span> ' + (itemCnt > 1 ? 'seconds' : 'second'));
                // tétel a kosárban
                wrapper
                    .find('ul')
                    .append(
                    itemTpl
                        .replace('TIME', getSelectedTime(' : '))
                        .replace('PRICE', itemPrice.toString())
                );
                // vásárlás összege
                $('.summary').html('Total price*: ' + result.total + ' <span class="currency-symbol">' + currency + '</span>');


                $('.item').removeClass('active');

                itemStatePending = false;

                callback();
            } else {
                order.msg(result.error);

                $('.item').removeClass('active');

                itemStatePending = false;
            }
        });
    };

    /**
     * Másodperc eltávolítása a kosárból
     *
     * @param t a másodperc pl 00:00:00
     * @param e event
     */
    order.removeFromBasket = function (t, e) {
        e.preventDefault();

        if (itemStatePending) {
            return false;
        }

        itemStatePending = true;

        var elem = $(e.target).parents('li');

        elem.find('.fa-times-circle').addClass('hidden');
        elem.find('.fa-cog').removeClass('hidden');

        // másodperc unlock
        var d = t.split(':'),
            hour = parseInt(d[0].replace(/O/g, '0')),
            minute = parseInt(d[1].replace(/O/g, '0')),
            second = parseInt(d[2].replace(/O/g, '0'));

        $.ajax({
            type: 'POST',
            url: siteUrl + '/unlockSecond',
            headers: siteToken,
            data: {
                h: hour,
                m: minute,
                s: second
            }
        }).success(function (result) {
            if (result.success) {
                order.setItemPrice(result.currentPrice).setContingent(result);

                // mennyiség csökkentése
                itemCnt--;
                // kosár elem összesen
                $('.item-summary').html('<i class="fa fa-shopping-cart"></i><span>' + itemCnt + '</span> ' + (itemCnt > 1 ? 'seconds' : 'second'));
                // vásárlás összegének frissítése
                $('.summary').html('Total price*: ' + result.total + ' <span class="currency-symbol">' + currency + '</span>');


                // elem eltávolítása a kosárból
                elem.remove();

                setLock(false);
            }

            itemStatePending = false;

            $('.basket li').css('cursor', 'pointer');
        });
    };

    /**
     * Oszlopkijelölések alaphelyzetbe állítása
     */
    order.resetCols = function () {
        var list = $('.list');
        // aktív oszlopok inaktiválása
        $('.tinfo').removeClass('active');
        $('.left-side .chooser').css('bottom', '-1000px');
        // kijelölt elemek
        setTimeout(function () {
            list.find('.item').removeClass('active');
        }, 50);
        // nincs kijelölt másodperc
        selected = {h: 0, m: 0, s: 0};
        // óra oszlop az aktív
        $('.hour-info').addClass('active');
        $('.left-side .hour.chooser').css('bottom', '-10px');
    };

    /**
     * Kosár tétel összesen
     *
     * @param {number} c
     * @return order
     */
    order.setItemCount = function (c) {
        itemCnt = c;

        return order;
    };

    /**
     * Egy másodperc ára
     *
     * @param v
     * @return {*|{}}
     */
    order.setItemPrice = function (v) {
        itemPrice = v;

        return order;
    };

    /**
     * Mentett kosár visszatöltésekor meghatározható kosár összesen
     *
     * @param t
     */
    order.setTotal = function (t) {
        total = t;

        return order;
    };

    /**
     * Inaktív óraszámok
     *
     * @param hours
     * @return {*|{}}
     */
    order.setInactiveHours = function (hours) {
        inactiveHours = hours;

        return order;
    };

    /**
     * Pénznem
     *
     * @param symbol
     * @return {*|{}}
     */
    order.setCurrency = function (symbol) {
        currency = symbol;

        return order;
    };

    order.to = function (url) {
        window.location.href = siteUrl + '/' + url;
    };

    order.msg = function (msg) {
        $.colorbox({
            html: '<div class="errormsg">' + msg + '</div>',
            width: 300,
            //height: 100,
            opacity: .5,
            transition: 'fade'
        });
    };

    /**
     * ÁFA kijelzése vásárló adatok lépésnél a total price alatt
     */
    order.recalculateVat = function () {
        var customerCountryId = $('#customer_country_id'),
            country_id = customerCountryId.val(),
            ctPrivate = $('#customer_type_private').val(),
            ctCommercial = $('#customer_type_commercial').val();

        // ha nincs kijelölve private/customer akkor nem számítható ki az ÁFA
        if (ctPrivate == '' && ctCommercial == '') {
            return false;
        }

        $.ajax({
            type: 'POST',
            url: siteUrl + '/order/getVat',
            headers: siteToken,
            data: {country_id: country_id, is_private: parseInt(ctPrivate) == 1}
        }).done(function (result) {
            /**
             * @var {{ success: boolean, reverse_charge: boolean, iseu: boolean, vat: number }} result
             */
            if (result.success) {
                customerCountryId.data('iseu', result.iseu);
                isEU = result.iseu;

                if (isEU) {
                    $('#customer_uid').attr('placeholder', 'UID number *');
                } else {
                    $('#customer_uid').attr('placeholder', 'UID number');
                }

                if (parseInt(result.reverse_charge)) {
                    $('#priceAsterisk').removeClass('hidden');
                    $('#taxinfo').text('*Reverse charge mechanism applies').removeClass('hidden');
                } else if (parseInt(result.vat) > 0) {
                    $('#priceAsterisk').removeClass('hidden');
                    $('#taxinfo').text('*Price includes ' + result.vat + '% VAT').removeClass('hidden');
                } else {
                    $('#priceAsterisk').addClass('hidden');
                    $('#taxinfo').addClass('hidden');
                }
            }
        });
    };

    order.uploadSave = function () {
        $('.link').removeClass('error');

        $.ajax({
            type: 'POST',
            url: siteUrl + '/order/uploadSave',
            headers: siteToken,
            data: {imageurl: $('form').serialize()}
        }).done(function (result) {
            if (result.success) {
                order.to('pic-a-second/customer');
            } else {
                order.msg(result.message);
                $('.link[data-second=' + result.sec + ']').addClass('error');
            }
        });
    };

    /**
     * Képkivágás megnyitása előtt a linkeket el kell menteni
     * 3.2.33
     *
     * @param second
     */
    order.toCrop = function (second) {
        $.ajax({
            type: 'POST',
            url: siteUrl + '/order/uploadSave',
            headers: siteToken,
            data: {imageurl: $('form').serialize(), iscrop: 'true'}
        }).done(function (result) {
            if (result.success) {
                order.to('pic-a-second/crop/' + second);
            } else {
                order.msg(result.message);
                $('.link[data-second=' + result.sec + ']').addClass('error');
            }
        });
    };

    order.cropInit = function (url, token, second) {
        siteUrl = url;
        siteToken = token;

        $('.cropper-container > img').cropper({
            wheelZoomRatio: .03,
            dragMode: 'move',
            viewMode: 3,
            aspectRatio: 800 / 600,
            rotatable: false,
            scalable: false,
            center: false,
            guides: false,
            highlight: false,
            dragCrop: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            minCanvasWidth: 300,
            minCropBoxWidth: 320,
            minCropBoxHeight: 240,
            autoCrop: false,
            crop: function (e) {
                cropData = {x: e.x, y: e.y, width: e.width, height: e.height, s: second};
            }
        });

        return order;
    };

    order.hideCropHelper = function () {
        $('.crop-helper').fadeOut(500);
    };

    order.cropDone = function () {
        if ($('.crop-overlay').is(':visible')) {
            order.to('pic-a-second/upload');

            return false;
        }

        var crop = $('.cropper-container > img');
        crop.cropper('crop');

        $.ajax({
            type: 'POST',
            url: siteUrl + '/order/cropDone',
            headers: siteToken,
            data: cropData
        }).done(function () {
            order.to('pic-a-second/upload');
        });
    };

    order.done = function () {
        $('#customer_form').trigger('submit');
    };

    /**
     * Feltöltött kép törlése upload nézetnél
     *
     * @param {number} second
     * @param event
     */
    order.deleteImage = function (second, event) {
        event.preventDefault();

        $.ajax({
            url: siteUrl + '/order/deleteimage/' + second,
            headers: siteToken
        }).done(function (result) {
            if (result.success) {
                var cnt = $(event.target).parents('.upload-item'),
                    done = cnt.find('.done');

                // képfeltöltött kép megjelenítése box alapállapot
                done.find('.image').html('');
                done.find('.preview').find('.wrapper').find('img').remove();
                done.find('.filename').html('');
                done.addClass('hidden');

                // feltöltő rész megjelenítése
                cnt.find('.handler').removeClass('hidden');
                // hover a képblokkról le
                cnt.find('.picup').removeClass('hoverable');
            }
        });
    };

    /**
     * Ügyfél adatok megadása
     * Fizetés előtti lépés
     *
     * @param url
     * @return {*|{}}
     * @param token
     */
    order.customerInit = function (url, token) {
        siteUrl = url;
        siteToken = token;

        var customerCountryId = $('#customer_country_id');

        isEU = customerCountryId.data('iseu');

        // form validáció
        $('#customer_form').validate({
            ignore: 'input[type=text]:hidden',
            rules: {
                firstname: 'required',
                lastname: 'required',
                address_1: 'required',
                city: 'required',
                postal_code: 'required',
                email: {
                    required: true,
                    email: true
                },
                company_name: 'required',
                uid: {
                    required: function () {
                        return isEU;
                    }
                },
                'private': {
                    required: function () {
                        return $('#customer_type_commercial').val() == 0;
                    }
                },
                commercial: {
                    required: function () {
                        return $('#customer_type_private').val() == 0;
                    }
                },
                apply_terms: 'required'
            },
            invalidHandler: function () {
                $('#terms-apply-error-message, #customer-type-error-message').hide();
            },
            errorPlacement: function (error, element) {
                if ($(element).attr('id') == 'apply_terms') {
                    $('#terms-apply-error-message').show();
                }

                //console.log($(element).attr('id'));

                if ($(element).attr('id') == 'customer_type_private' || $(element).attr('id') == 'customer_type_commercial') {
                    $('#customer-type-error-message').show();
                }
            }
        });

        // radio gombok kezelése (ügyféltípus, fizetési módok, ászf elfogadása)
        var radio = $('.radio-button');
        radio.on('click', function () {
            var input = $(this).find('input'),
                indicator = $(this).find('.sprite.indicator'),
                group = $(this).data().group,
                v = parseInt(input.val());

            if (v) {
                if (typeof group == 'undefined' && !$(this).hasClass('disabled')) {
                    indicator.removeClass('sprite-radio-on').addClass('sprite-radio-off');
                    input.val('');
                }
            } else {
                radio.filter('[data-group=' + group + ']').find('input').val('');
                radio.filter('[data-group=' + group + ']').find('.sprite.indicator').removeClass('sprite-radio-on').addClass('sprite-radio-off');

                indicator.removeClass('sprite-radio-off').addClass('sprite-radio-on');
                input.val(1);
                radio.filter('[data-group=' + group + ']').parent().removeClass('active');
                $(this).parent().addClass('active');

                // kapcsolt hibaüzenet mező elrejtése, ha van
                $($(this).data('error')).hide();
            }

            if (parseInt($('input[name=commercial]').val())) {
                $('.company-extra').removeClass('hidden');
            } else {
                $('.company-extra').addClass('hidden');
            }

            // vásárló típus kihat az áfa kijelzésre
            order.recalculateVat();
        });

        // feltöltött kép nagyítása
        $('.colorbox').colorbox({ maxWidth: '90%', maxHeight: '90%' });

        // áfa kijelzése függ a kiválasztott országtípustól
        customerCountryId.on('change', function () {
            // IP alapján beazonosított ország ugyan az mint a kiválasztott ország?
            if ($(this).data('located') != $(this).val()) {
                order.msg('Your selected country does not match your IP location (and/or UID location). Please check your input again before you proceed.');
            }

            order.recalculateVat();
        });

        return order;
    };

    order.uploadInit = function (url, token) {
        siteUrl = url;
        siteToken = token;

        $('.picup').find('input.dial').knob({
            width: 44,
            height: 44,
            fgColor: '#1EA667'
        });

        $('.upload').find('.help').on('click', function () {
            $(this).toggleClass('open');
        });

        // a képfeltöltés
        $('.fileupload').fileupload({
            //dataType: 'json',
            url: siteUrl + '/imgUpload?_token=' + token['X-CSRF-TOKEN'],
            submit: function (e, data) {
                // másodperc
                data.formData = {s: $(this).data().second};
                // más nézet jelenik meg képfeltöltés folyamatában
                var cnt = $(this).parents('.picup');
                // feltöltő layer elrejtése
                cnt.find('.handler').addClass('hidden');
                // progress réteg megjelenítése
                cnt.find('.progress').removeClass('hidden');
                // fájlnév kijelzése
                cnt.find('.filename').text(data.files[0].name);
            },
            progress: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                // progress értékének kijelzése knob.js
                $(this).parents('.picup').find('input.dial').val(progress).trigger('change');

                // Feltöltés közben tiltjuk az azonnali új feltöltést
                // $('input[type=file]').attr('disabled', 'disabled');
                // $('.button').addClass('disabled');
            },
            fail: function (e, data) {
                // Feltöltés engedélyezése
                // $('input[type=file]').removeAttr('disabled');
                // $('.button').removeClass('disabled');

                var cnt = $(this).parents('.picup');
                order.msg('Sorry. We have encountered a problem during file upload. Please try again, choose a different picture or contact us.');
                cnt.find('.handler').removeClass('hidden');
                cnt.find('.progress').addClass('hidden');
            },
            done: function (e, data) {
                //Feltöltés engedélyezése
                // $('input[type=file]').removeAttr('disabled');
                // $('.button').removeClass('disabled');

                var cnt = $(this).parents('.picup');
                if (data.result.success) {
                    cnt.find('.image').append($('<img height="44" class="thumbnail"/>').attr('src', data.result.image + '?t=' + new Date().getTime() ));
                    cnt.find('.wrapper').prepend($('<img height="130"/>').attr('src', data.result.previewImage + '?t=' + new Date().getTime() ));
                    // feltöltés kész
                    cnt.addClass('hoverable');
                    // progress réteg elrejtése
                    cnt.find('.progress').addClass('hidden');
                    // kép szerkesztő réteg megjelenítése
                    cnt.find('.done').removeClass('hidden');
                } else {
                    order.msg(data.result.message);
                    cnt.find('.handler').removeClass('hidden');
                    cnt.find('.progress').addClass('hidden');
                }
            }
        });

        // link placeholder kezelése
        // elemre kattintásra eltűnik a címke, megjelenik az input
        var linksField = $('.link');
        linksField.on('click', function () {
            var inp = $(this).find('input');
            if (inp.hasClass('hidden')) {
                // input rejtve
                $(this).find('.text').addClass('hidden');
                inp.removeClass('hidden').focus();
            }
        });
        // input fókuszvesztésekor eltűnnek az inputok megjelennek a címkék
        linksField.find('input').on('blur', function () {
            if ($(this).val() == '') {
                var placeholder = $(this).prev('.text');
                if (placeholder.hasClass('hidden')) {
                    // input rejtve
                    placeholder.removeClass('hidden');
                    $(this).addClass('hidden');
                }
            }
        });

        var previewClose = false,
            preview = $('.preview');
        // img preview
        $('.img-container').on('mouseenter', function () {
            $('.preview').hide();
            $('.upload-item').css({zIndex: 1});

            $(this).parents('.picup').removeClass('hoverable');
            $(this).find('.preview').show();
            $(this).parents('.upload-item').css({zIndex: 2});

            previewClose = false;
        }).on('mouseleave', function () {
            $(this).parents('.picup').addClass('hoverable');
            previewClose = true;
        });

        preview.on('mouseenter', function () {
            previewClose = false;
        }).on('mouseleave', function () {
            previewClose = true;
        });

        setInterval(function () {
            if (previewClose) {
                $('.preview').hide();
            }
        }, 600);
    };

    /**
     * Másoperc kosárba tétele oldal betöltéskor
     * ?add={number} létezik az urlben
     *
     * @param {number} h
     * @param {number} m
     * @param {number} s
     */
    order.preSelect = function (h, m, s) {
        selected.h = h;
        selected.m = m;
        selected.s = s;
    };

    order.init = function (url, token, preselect) {
        siteUrl = url;
        siteToken = token;

        $(document).ajaxComplete(function (event, request) {
            // ha a session lejár reload mezőt tartalmazni fog a response, az oldalt frissíteni kell
            if (typeof request.responseJSON.reload !== 'undefined') {
                window.location.reload();
            }
        });

        // ha van kijelölt másodperc az oszlopokat a kijelölés látható területére kell scrollozni

        // oszlopok scrollozhatók
        chooserScroll();
        // kiválasztás vezérlése
        chooserControl(preselect);
        // zárolt másodpercek keresése
        // elvileg oldalbetöltéskor a zárolt órák inaktívak
        //lockSearcher('hour');

        var wrapper = $('.items-wrapper'),
            throttleTimeout;

        // zárolt másodpercek keresése és beállítása
        setLock(true);

        return order;
    };

    order.getIsHourDone = function () {
        return col1Done;
    };

    order.getIsMinuteDone = function () {
        return col2Done;
    };

    return order;

}(Order || {}, jQuery));